
import Vue from 'vue';

import { _deobfuscateLink, _isObfuscatedLink } from '@/utilities/misc/seoLinkObfuscation';

enum ELinkType {
  Native = 'a',
  NuxtLink = 'NuxtLink',
}

type LinkType = `${ELinkType}`;

// NOTE: How to create SEO links obfuscation while staying accessible. Please read : https://www.lalutineduweb.fr/en/seo-links-obfuscation-accessibility-problems/
export default Vue.extend({
  name: 'BaseLink',
  props: {
    to: {
      type: [String, Object],
      default: null,
    },
    href: {
      type: String,
      default: null,
    },
    isUnstyled: {
      type: Boolean,
      default: false,
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      link: this.href, // Prop's value only used for data initialization.
    };
  },
  computed: {
    linkType(): LinkType {
      if (this.to) {
        return ELinkType.NuxtLink;
      }

      return ELinkType.Native;
    },
    isObfuscatedLink(): boolean {
      return _isObfuscatedLink(this.link);
    },
    obfuscatedHref(): string | null {
      return this.isObfuscatedLink ? this.href : null;
    },
    deobfuscatedHref(): string | null {
      return this.isObfuscatedLink ? null : this.link;
    },
  },
  methods: {
    handleClick(event: Event): void {
      // NOTE: simple accessibility safe guard for users browsing with their voice (no click/focus/mouseover possible).
      if (this.isObfuscatedLink) {
        this.$errorMonitor.report({
          message: 'Obfuscated link clicked',
          data: {
            href: this.href,
            link: this.link,
            currentRoute: this.$route.fullPath,
            userAgent: navigator.userAgent,
          },
        }, 'fatal');

        this.deobfuscate();
        window.location.href = this.link;
      }

      this.$emit('click', event);
    },
    handleFocus(event: Event): void {
      this.deobfuscate();

      this.$emit('focus', event);
    },
    handleMouseover(): void {
      this.deobfuscate();
    },
    deobfuscate(): void {
      if (!this.isObfuscatedLink) {
        return;
      }

      this.link = _deobfuscateLink(this.link);
    },
  },
});
