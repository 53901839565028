import { render, staticRenderFns } from "./FooterMain.vue?vue&type=template&id=d6c0d246"
import script from "./FooterMain.vue?vue&type=script&lang=ts"
export * from "./FooterMain.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormNewsletter: require('/home/runner/work/webapp/webapp/components/organisms/form/FormNewsletter.vue').default,NavigationSocial: require('/home/runner/work/webapp/webapp/components/molecules/navigation/NavigationSocial.vue').default,NavigationFooter: require('/home/runner/work/webapp/webapp/components/molecules/navigation/NavigationFooter.vue').default,SelectLocale: require('/home/runner/work/webapp/webapp/components/molecules/select/SelectLocale.vue').default,SelectCurrency: require('/home/runner/work/webapp/webapp/components/molecules/select/SelectCurrency.vue').default})
