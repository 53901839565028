
import Vue, { PropType } from 'vue';
import { RawLocation } from 'vue-router';

import { ERouteName } from '@/domain/core/Routes.enum';
import { ESchema, ESchemaProduct } from '@/domain/core/Schema.enum';
import { EProductStatus, EQualityLevel, Product } from '@/domain/product/types';
import { imagePlaceholderId } from '@/infrastructure/externalServices/imageCdn/types';

export default Vue.extend({
  name: 'CardProduct',
  props: {
    isLarge: {
      type: Boolean,
      default: false,
    },
    isLite: {
      type: Boolean,
      default: false,
    },
    isUserAdmin: {
      type: Boolean,
      default: false,
    },
    isUserPro: {
      type: Boolean,
      default: false,
    },
    isUserSelectionsManager: {
      type: Boolean,
      default: false,
    },
    isUserProductBooster: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object as PropType<Product>,
      required: true,
    },
    shouldHideDiscountBadge: {
      type: Boolean,
      default: false,
    },
    shouldHaveAnSeoReadyTitle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ESchema,
      ESchemaProduct,
    };
  },
  computed: {
    pageHref(): string | undefined {
      const { sku = '', slug = '', __queryID: queryID } = this.product || {};
      const routerOptions: RawLocation = {
        name: ERouteName.Product,
        params: { sku, slug },
      };

      if (queryID) {
        routerOptions.hash = `#${new URLSearchParams({ queryID })}`;
      }

      const route = this.$router.resolve(routerOptions);

      return route?.href;
    },
    productTitleTag(): keyof HTMLElementTagNameMap {
      return this.shouldHaveAnSeoReadyTitle ? 'h3' : 'span';
    },
    discountPercentage(): string | null {
      const specialPrice = this.product?.pricing?.specialPrice;
      const price = this.product?.pricing?.price;

      if (!specialPrice || !price) {
        return null;
      }

      const discount = Math.round(((specialPrice - price) / price) * 100);

      return discount <= -10 ? `${discount}%` : null;
    },
    imageSource(): string {
      return this.product.mainPicture?.id || imagePlaceholderId;
    },
    isSoldOut(): boolean {
      return this.product.status === EProductStatus.SoldOut;
    },
    isWishlistReady(): boolean {
      return !!this.productId;
    },
    productId(): string {
      const { legacyId, id } = this.product;

      return legacyId || id;
    },
    shopRating(): number | null {
      return this.product?.shop?.statistics?.rating || null;
    },
    shouldDisplayDiscount(): boolean {
      return !this.isUserPro
        && !this.isUserAdmin
        && !!this.discountPercentage
        && this.shouldDisplayPricing
        && !this.shouldHideDiscountBadge;
    },
    shouldDisplayPricing(): boolean {
      return !!this.product?.pricing?.price && !this.isSoldOut;
    },
    shouldDisplayQualityMark(): boolean {
      return this.isUserAdmin
        && this.product?.qualityLevel === EQualityLevel.ThreeStars
        && !this.isLite;
    },
    shouldEnableWishlistControls(): boolean {
      return this.isWishlistReady && !this.isLite;
    },
    shouldEnableSelectionTriage(): boolean {
      return this.isUserSelectionsManager && !this.isLite;
    },
    shouldEnableProductBoosting(): boolean {
      return this.isUserProductBooster && !this.isLite;
    },
    wishlistButtonPortalTargetName(): string {
      return `add-to-board-dropdown-toggle-${this.productId}`;
    },
  },
  methods: {
    handleClick(): void {
      this.$emit('click-card-product');
    },
  },
});
