import { EHttpMethod } from '@/domain/core/http/HttpMethod.enum';
import { HttpClientInstance, HttpRequest } from '@/infrastructure/core/http/HttpRequest.interface';
import { HttpResponse } from '@/infrastructure/core/http/HttpResponse.interface';
import { _buildURL } from '@/utilities/http/buildURL';

export default class HttpService {
  readonly #httpClient: HttpClientInstance;

  constructor(axios: HttpClientInstance) {
    this.#httpClient = axios;
  }

  request<T = any, R = HttpResponse<T>>(request: HttpRequest): Promise<R> {
    const url = _buildURL({
      path: request.path,
      nestedRouteParams: request.nestedRouteParams,
    });

    return this.#httpClient.request<T, R>({
      data: request.payload,
      headers: request?.headers,
      method: request?.method || EHttpMethod.Get,
      params: request?.queryParams || {},
      responseType: request?.responseType,
      url,
    });
  }
}
