import { CookieValue } from 'cookie-universal-nuxt';

export enum ECookie {
  AppDownloadPromptDismissed = 'appDownloadPromptDismissed',
  CsrfToken = 'csrfToken',
  Currency = 'currency',
  I18nRedirected = 'i18n_redirected',
  Language = 'language',
  NewsletterModalDismissed = 'accept_newsletter', // NOTE: Legacy cookie - its value doesn't matter.
  UserAbTests = 'userAbTests',
  UserCartId = 'userCartId',
  UserCurrentOrderId = 'userCurrentOrderId',
  UserId = 'userId',
  UserSegmentId = 'userSegmentId',
  UserToken = 'userToken',
}

export type AllCookies = Record<`${ECookie}`, CookieValue>;
