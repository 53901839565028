
import Vue, { PropType } from 'vue';

import IcCheck from '@/assets/svg/ic_check.svg?inline';
import IcLoader from '@/assets/svg/ic_loader.svg?inline';
import IcRocket from '@/assets/svg/ic_rocket.svg?inline';
import { Product } from '@/domain/product/types';

// NOTE: this component should only be visible to admins. No need to make it accessible to screen readers.
export default Vue.extend({
  name: 'ButtonProductBoost',
  components: {
    IcCheck,
    IcLoader,
    IcRocket,
  },
  props: {
    productId: {
      type: String as PropType<Product['id'] | Product['legacyId']>,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      isProductBoosted: false,
    };
  },
  methods: {
    async boostProduct(): Promise<void> {
      try {
        this.isLoading = true;

        await this.$repositories.product.boost(this.productId);

        this.isProductBoosted = true;
      } catch (err) {
        this.$errorMonitor.report(err, 'info');
      } finally {
        this.isLoading = false;
      }
    },
  },
});
