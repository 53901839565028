
import Vue from 'vue';

import { ERouteName } from '@/domain/core/Routes.enum';
import { EGtmEventHeader } from '@/infrastructure/externalServices/gtm/DataLayer.enum';
import { ClickEventPayload } from '@/infrastructure/externalServices/gtm/generic/ClickEvent.interface';

export default Vue.extend({
  name: 'HeaderBanners',
  computed: {
    mainBannerContent(): string | null {
      return this.$accessor.header.headerBannerContent;
    },
    shouldDisplayBanner(): boolean {
      const isMobileProductPage = this.$route?.name === ERouteName.Product
        && this.$device?.isMobile;

      return !!this.mainBannerContent && !isMobileProductPage;
    },
  },
  methods: {
    handleBannerTracking(payload: ClickEventPayload): void {
      this.$gtm.push({
        event: EGtmEventHeader.ClickBanner,
        ...payload,
      });
    },
  },
});
