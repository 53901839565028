
import Vue from 'vue';

import { EGtmEventUser, ENewsletterSubmitLocation } from '@/infrastructure/externalServices/gtm/DataLayer.enum';

export default Vue.extend({
  name: 'FooterMain',
  methods: {
    handleTracking(): void {
      this.$gtm.push({
        event: EGtmEventUser.NewsletterOptin,
        location: ENewsletterSubmitLocation.Footer,
      });
    },
  },
});
