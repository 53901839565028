import { HttpRequest } from '@/infrastructure/core/http/HttpRequest.interface';

export const _buildURL = ({ path, nestedRouteParams }: Partial<HttpRequest>): string => {
  let fullPath = `${path || ''}`;

  if (nestedRouteParams) {
    for (const param in nestedRouteParams) {
      fullPath = fullPath.replace(`:${param}`, nestedRouteParams[param]);
    }
  }

  return fullPath;
};
