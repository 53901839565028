export enum EFeatureFlags {
  CheckoutDeliveryQuotationRequest = 'checkout-delivery-quotation-request',
  CheckoutPickupPointMap = 'checkout-pickup-point-map',
  CrmModalAppDownloadPromptForMobileOnly = 'crm-modal-app-download-prompt-for-mobile-only',
  Sales = 'sales-web',
  SeoObfuscateMainMenuLinks = 'seo-obfuscate-main-menu-links',
}

export type FeatureFlags = Record<EFeatureFlags, boolean>;

interface LaunchDarklyLink {
  href: string;
  type: string;
}

interface Target {
  contextKind: string;
  values: string[];
  variation: number;
}

export interface LaunchDarklyEnvironment {
  _environmentName: string;
  _site: {
    href: string;
    type: string;
  };
  _summary: {
    prerequisites: number;
    variations: Record<string, any>;
  };
  archived: boolean;
  lastModified: number;
  offVariation: number;
  on: boolean;
  salt: string;
  sel: string;
  targets: Target[];
  trackEvents: boolean;
  trackEventsFallthrough: boolean;
  version: number;
}

// https://apidocs.launchdarkly.com/tag/Feature-flags/#operation/getFeatureFlags
export interface LaunchDarklyApiItem {
  _links: {
    parent: LaunchDarklyLink;
    self: LaunchDarklyLink;
  };
  _maintainer: {
    _id: string;
    _links: {
      self: LaunchDarklyLink;
    };
    email: string;
    role: string;
  };
  _version: number;
  archived: boolean;
  clientSideAvailability: {
    usingEnvironmentId: boolean;
    usingMobileKey: boolean;
  };
  creationDate: number;
  customProperties: {};
  description: string;
  environments: Record<string, LaunchDarklyEnvironment>;
  experiments: {
    baselineIdx: number;
    items: unknown[];
  };
  goalIds: string[];
  includeInSnippet: string;
  key: `${EFeatureFlags}`;
  kind: boolean;
  maintainerId: string;
  name: string;
  tags: string[];
  temporary: boolean;
  variations:
    {
      _id: string;
      value: boolean;
    }[];
}
