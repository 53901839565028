
import Vue, { PropType } from 'vue';

import { EFacetNameRefinementList } from '@/domain/core/algolia/types';
import { MainMenuItem } from '@/infrastructure/core/components/navigation/MainMenuItem.interface';
import { EFeatureFlags } from '@/infrastructure/externalServices/launchDarkly/types';
import { _isObfuscatedLink, _obfuscateLink } from '@/utilities/misc/seoLinkObfuscation';

const hoverDelayInMs = 200;

export default Vue.extend({
  name: 'NavigationMain',
  props: {
    navigation: {
      type: Array as PropType<MainMenuItem[]>,
      required: true,
    },
  },
  data() {
    return {
      activeIndex: 0,
      isMouseOut: false,
      hoverDelayTimer: null as ReturnType<typeof setTimeout> | null,
    };
  },
  computed: {
    // NOTE: obfuscate every link except the current category (SEO).
    obfuscatedNavigation(): MainMenuItem[] {
      let obfuscatedNavigation = [...this.navigation];

      const categorySlugLevel1 = this.$route?.params?.[EFacetNameRefinementList.CategoryLevel1];

      if (categorySlugLevel1 && this.$accessor?.featureFlags?.[EFeatureFlags.SeoObfuscateMainMenuLinks]) {
        const obfuscateMainMenuLinks = (items: MainMenuItem[]): MainMenuItem[] => {
          return items.map((item) => {
            let fmtItem = { ...item };

            if (item.slug !== categorySlugLevel1) {
              fmtItem = {
                ...item,
                url: _isObfuscatedLink(item.url) ? item.url : _obfuscateLink(item.url),
                child: item.child ? obfuscateMainMenuLinks(item.child) : [],
              };
            }

            return fmtItem;
          });
        };

        obfuscatedNavigation = obfuscateMainMenuLinks([...this.navigation]);
      }

      return obfuscatedNavigation;
    },
  },
  methods: {
    clearTimer() {
      if (this.hoverDelayTimer) {
        clearTimeout(this.hoverDelayTimer);
      }
    },
    handleActiveMenu(index: number) {
      this.clearTimer();
      this.hoverDelayTimer = setTimeout(() => {
        this.activeIndex = index;
        this.isMouseOut = false;
      }, hoverDelayInMs);
    },
    handleMouseLeave(): void {
      this.clearTimer();
      this.isMouseOut = true;
    },
  },
});
