import { ApiBase } from '@/domain/core/api/ApiBase.interface';
import { ApiItemBase } from '@/domain/core/api/ApiItemBase.interface';
import { ApiLegacyBase } from '@/domain/core/api/ApiLegacyBase.interface';
import { EEmbedsName, Embeds } from '@/domain/core/api/embeds.interface';
import { PaginatedList, PaginationQueryParameters } from '@/domain/core/api/types';
import { EApiVersion } from '@/domain/core/http/ApiVersion.enum';
import { EHeader } from '@/domain/core/http/Header';
import { EHeaderLegacy } from '@/domain/core/http/HeaderLegacy';
import { EHttpMethod } from '@/domain/core/http/HttpMethod.enum';
import { SecurityTokenProvider } from '@/domain/core/SecurityService.interface';
import {
  DeliveryOptionsRequestParams,
  EProductsListIdentifierType as EIdentifierType,
  PayloadRequestDeliveryQuotation,
  ProductRepositoryInterface,
  SimilarProductsRequestParams,
} from '@/domain/product/productRepository.interface';
import { LegacyCategories, Product, ProductIdentifier, UsableDeliveryOption } from '@/domain/product/types';
import { transform, transformList, transformListPaginated } from '@/infrastructure/core/apiData.transformer';
import { Headers, HttpRequest } from '@/infrastructure/core/http/HttpRequest.interface';
import HttpService from '@/services/http.service';

const resourcePath = '/products';

type ProductEmbeds = Pick<Embeds, EEmbedsName.Categories | EEmbedsName.Category | EEmbedsName.Shop | EEmbedsName.Statistics | EEmbedsName.Story>;

export default class ProductRepository implements ProductRepositoryInterface {
  readonly #basePath: string;
  readonly #httpService: HttpService;
  readonly #i18nLocale: string;
  readonly #legacyBasePath: string;
  readonly #security: SecurityTokenProvider;

  constructor(
    httpService: HttpService,
    basePath: string,
    security: SecurityTokenProvider,
    i18nLocale: string,
    legacyBasePath: string,
  ) {
    this.#basePath = basePath;
    this.#httpService = httpService;
    this.#i18nLocale = i18nLocale;
    this.#legacyBasePath = legacyBasePath;
    this.#security = security;
  }

  async boost(id: ProductIdentifier): Promise<Product> {
    const token = this.#security.getToken();

    // These are shipped by default by this endpoint. Though, the product returned isn't used.
    type PayloadEmbeds = Pick<
      Embeds,
      EEmbedsName.Categories | EEmbedsName.Category | EEmbedsName.Shop | EEmbedsName.Story | EEmbedsName.Pricing | EEmbedsName.Picking
    >;
    const { content } = await this.#httpService.request<ApiItemBase<Product, PayloadEmbeds>>({
      headers: {
        [EHeader.Accept]: EApiVersion.V2,
        [EHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Put,
      path: `${this.#basePath}${resourcePath}/${id}/boost`,
    });

    return transform(content);
  }

  async countProducts(userId: string, statuses: string[] = []): Promise<number> {
    const token = this.#security.getToken();

    const queryParams: HttpRequest['queryParams'] = { user: userId };

    for (const [index, status] of statuses.entries()) {
      queryParams[`status[${index}]`] = status;
    }

    const { content } = await this.#httpService.request<ApiLegacyBase<number>>({
      headers: {
        [EHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Get,
      path: `${this.#legacyBasePath}${resourcePath}/count`,
      queryParams,
    });

    return content?.content;
  }

  async getCategories(slug: string | null = null, parentSlug: string | null = null): Promise<LegacyCategories> {
    const { content } = await this.#httpService.request<ApiLegacyBase<LegacyCategories>>({
      headers: {
        [EHeader.Accept]: EApiVersion.V1,
        [EHeaderLegacy.Locale]: this.#i18nLocale,
      },
      method: EHttpMethod.Get,
      path: `${this.#legacyBasePath}/categories`,
      queryParams: {
        slug,
        parent_slug: parentSlug,
        include_inactive: true,
      },
    });

    if (content?.succeed === false) {
      throw new Error('Category not found');
    }

    return content?.content;
  }

  async getCategory(id: string): Promise<LegacyCategories> {
    const { content } = await this.#httpService.request<ApiLegacyBase<LegacyCategories>>({
      headers: {
        [EHeader.Accept]: EApiVersion.V1,
        [EHeaderLegacy.Locale]: this.#i18nLocale,
      },
      method: EHttpMethod.Get,
      path: `${this.#legacyBasePath}/categories/${id}`,
    });

    if (content?.succeed === false) {
      throw new Error('Category not found');
    }

    return content?.content;
  }

  async getProduct(id: ProductIdentifier): Promise<Product> {
    const embeds = [
      EEmbedsName.Categories,
      EEmbedsName.Category,
      EEmbedsName.Shop,
      `${EEmbedsName.Shop}.${EEmbedsName.User}`,
      `${EEmbedsName.Shop}.${EEmbedsName.Statistics}`,
      EEmbedsName.Story,
    ].join(',');

    const { content } = await this.#httpService.request<ApiItemBase<Product, ProductEmbeds>>({
      headers: {
        [EHeader.Accept]: EApiVersion.V2,
      },
      method: EHttpMethod.Get,
      path: `${this.#basePath}${resourcePath}/${id}`,
      queryParams: {
        embeds,
      },
    });

    return transform(content);
  }

  async getProductLite(id: ProductIdentifier): Promise<Product> {
    const { content } = await this.#httpService.request<ApiItemBase<Product>>({
      headers: {
        [EHeader.Accept]: EApiVersion.V2,
      },
      method: EHttpMethod.Get,
      path: `${this.#basePath}${resourcePath}/${id}`,
    });

    return transform(content);
  }

  async getProducts(
    identifiersList: string[] = [],
    identifierType = EIdentifierType.Skus,
    parameters?: PaginationQueryParameters,
  ): Promise<PaginatedList<Product> | null> {
    if (!identifiersList?.length) {
      return null;
    }

    const { content } = await this.#httpService.request<ApiBase<Product>>({
      headers: {
        [EHeader.Accept]: EApiVersion.V2,
      },
      method: EHttpMethod.Get,
      path: `${this.#basePath}${resourcePath}`,
      queryParams: {
        [identifierType]: identifiersList.join(','),
        limit: parameters?.limit || null,
        maxResults: parameters?.maxResults || null,
        page: parameters?.page || null,
      },
    });

    return transformListPaginated(content);
  }

  async getSimilarProducts(id: ProductIdentifier, parameters?: SimilarProductsRequestParams): Promise<PaginatedList<Product>> {
    const defaultMaxResults = 15;
    const { content } = await this.#httpService.request<ApiBase<Product>>({
      headers: {
        [EHeader.Accept]: EApiVersion.V2,
      },
      method: EHttpMethod.Get,
      path: `${this.#basePath}${resourcePath}/${id}/similars`,
      queryParams: {
        limit: parameters?.limit || defaultMaxResults,
        maxResults: parameters?.maxResults || defaultMaxResults,
        onlyPriceReduced: parameters?.onlyPriceReduced || null,
        page: parameters?.page || null,
      },
    });

    return transformListPaginated(content);
  }

  async getUsableDeliveryOptions(productId: string, deliveryOptionRequestParams: DeliveryOptionsRequestParams): Promise<UsableDeliveryOption[]> {
    const headers: Headers = {
      [EHeader.Accept]: EApiVersion.V2,
    };

    try {
      const token = this.#security.getToken();

      if (token) {
        headers[EHeader.Authorization] = `Bearer ${token}`;
      }
    } catch {}

    const { content } = await this.#httpService.request<ApiBase<UsableDeliveryOption>>({
      headers,
      method: EHttpMethod.Get,
      path: `${this.#basePath}/products/${productId}/usable-delivery-options`,
      queryParams: {
        ...deliveryOptionRequestParams,
      },
    });

    return transformList(content?.items);
  }

  async requestDeliveryQuotation(productId: string, payload: PayloadRequestDeliveryQuotation): Promise<void> {
    const token = this.#security.getToken();

    await this.#httpService.request<void>({
      headers: {
        [EHeader.Accept]: EApiVersion.V2,
        [EHeader.Authorization]: `Bearer ${token}`,
      },
      method: EHttpMethod.Post,
      path: `${this.#basePath}${resourcePath}/${productId}/delivery-quotation`,
      payload,
    });
  }
}
