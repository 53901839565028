export enum EMaxAge {
  OneMinute = 60,
  FifteenMinutes = EMaxAge.OneMinute * 15,
  OneHour = EMaxAge.OneMinute * 60,
  OneDay = EMaxAge.OneHour * 24,
  MaxAllowedByGDPR = EMaxAge.OneDay * 395,
  OneMonth = EMaxAge.OneDay * 30,
  SixMonths = EMaxAge.OneMonth * 6,
  TenDays = EMaxAge.OneDay * 10,
  TwoDays = EMaxAge.OneDay * 2,
}
