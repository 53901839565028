import { EAppEnvironment } from '@/domain/core/Environment.enum';
import { EHeader } from '@/domain/core/http/Header';
import { EHttpMethod } from '@/domain/core/http/HttpMethod.enum';
import { HttpCacheClientInterface } from '@/infrastructure/core/http/httpCacheClient';
import { FeatureFlags, LaunchDarklyApiItem } from '@/infrastructure/externalServices/launchDarkly/types';

export default class LaunchDarklyClient {
  readonly #httpCache: HttpCacheClientInterface;
  readonly #accessToken: string;
  readonly #appEnvironment: string;
  readonly #launchDarklyApiBaseUrl = 'https://app.launchdarkly.com/api/v2';
  readonly #launchDarklyFeatureFlagsCacheKey = 'launch-darkly-flags-visitor';

  constructor(httpCache: HttpCacheClientInterface, accessToken: string, appEnvironment: EAppEnvironment) {
    this.#httpCache = httpCache;
    this.#accessToken = accessToken;
    this.#appEnvironment = appEnvironment;
  }

  async getAllFeatureFlagStatuses(userEmail: string | null = null): Promise<Partial<FeatureFlags>> {
    const featureFlagStatuses: Partial<FeatureFlags> = {};
    const launchdarklyEnvironment = this.#appEnvironment === EAppEnvironment.Production ? 'production' : 'test';
    const { content } = await this.#httpCache.getCachedResponse(this.#launchDarklyFeatureFlagsCacheKey,
      {
        path: `${this.#launchDarklyApiBaseUrl}/flags/default`,
        method: EHttpMethod.Get,
        headers: {
          [EHeader.Authorization]: this.#accessToken,
        },
        queryParams: {
          env: launchdarklyEnvironment,
          summary: '0', // https://apidocs.launchdarkly.com/tag/Feature-flags#operation/getFeatureFlags!in=query&path=summary&t=request
        },
      },
      {
        EX: 3600,
      },
    );
    const launchDarklyApiItems: LaunchDarklyApiItem[] = content?.items || [];

    for (const featureFlag of launchDarklyApiItems) {
      if (featureFlag?.key) {
        const featureFlagProps = featureFlag?.environments?.[launchdarklyEnvironment];

        let isFeatureFlagOn = featureFlagProps?.on || false;

        if (userEmail) {
          for (const target of featureFlagProps?.targets) {
            const isIndividualVariation = target.contextKind === 'user';
            const isVariationOn = target.variation !== featureFlagProps.offVariation;
            const isUserTargeted = target.values.includes(userEmail);
            const isFeatureFlagOnForSpecificUser = isIndividualVariation && isUserTargeted && isVariationOn;

            if (isUserTargeted) {
              isFeatureFlagOn = isFeatureFlagOnForSpecificUser;
            }
          }
        }

        featureFlagStatuses[featureFlag.key] = isFeatureFlagOn;
      }
    }

    return featureFlagStatuses;
  }
}
