import { actionTree, mutationTree } from 'typed-vuex';

import { EFeatureFlags as MutationType } from '@/domain/core/store/MutationType.enum';
import { EFeatureFlags, FeatureFlags } from '@/infrastructure/externalServices/launchDarkly/types';
import { accessorType } from '@/store';

export const state = (): FeatureFlags => Object.fromEntries(
  Object.values(EFeatureFlags)
    .map((value) => ([value, false])),
) as FeatureFlags;

export const mutations = mutationTree(state, {
  [MutationType.SET_FEATURE_FLAGS]: (state, payload: Partial<FeatureFlags>): void => {
    const filterFeatureFlags = Object.fromEntries(
      Object.entries(state)
        // @ts-expect-error - TS can't infer the type of the key 🤡
        .map(([key]) => ([key, payload?.[key] || false])),
    ) as FeatureFlags;

    Object.assign(state, filterFeatureFlags);
  },
});

export const actions = actionTree({ state, mutations }, {
  async getAllFeatureFlags(_): Promise<void> {
    const accessor: typeof accessorType = this.app.$accessor;

    try {
      const featureFlagStatuses = await this.$services.launchDarklyClient.getAllFeatureFlagStatuses(accessor.user?.email || null);

      accessor.featureFlags.SET_FEATURE_FLAGS(featureFlagStatuses);
    } catch (err) {
      this.$errorMonitor.report(err, 'error');
    }
  },
});
